body {
  font-family: "Poppins" !important;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 30px;
}

.a-white {
  text-decoration: none;
  color: #fff;
}

.a-white:hover {
  text-decoration: underline;
  color: #fff;
}

.align-text {
  text-align: center;
}

.bodyContainer {
  position: relative;
}

.desktop-bg {
  display: block;
  z-index: -2;
  opacity: 50%;
  height: 35rem !important;
}

.mobile-bg {
  display: none;
  z-index: -2;
  position: relative;
}

.body-bg {
  position: absolute;
  z-index: -2;
}

.dots-group {
  position: absolute;
  left: 1%;
  margin-top: 20%;
  max-width: 50px;
}

.posts {
  width: 40%;
  height: 450px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.menus>li{
  border-right: 1px solid #083f88;
}
.menus>li:last-child{
  border:none;
}
.postimages {
  width: 650px;
  cursor: pointer;
}

.leftarrow, .rightarrow{
  cursor: pointer;
  margin: 0 0 2rem 0;
  z-index: 2;

}
.leftarrow{
  margin-left: 30%;

}
.rightarrow{
  margin-left: 30%;

}
@media only screen and (min-width: 320px) and (max-width: 991px) {
  .postimages {
    width: 250px;
  }
  .posts {
    height: 120px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .leftarrow, .rightarrow{
    display:none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 356px) {
  .postimages {
    width: 230px;
  }
}

@media only screen and (min-width: 357px) and (max-width: 599px) {
  .postimages {
    width: 270px;
  }
  .posts {
    margin-bottom: 45px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .postimages {
    width: 400px;
  }
  .posts {
    height: 300px;
  }
}

.text-justify {
  text-align: justify;
}

.wsj-business{
  float: right;
  margin-top: -15rem;
  margin-right: 2rem;
  position: relative;
  width: 42%;
}


.wsj-btn {
  position: absolute;
  background-color: #ffffff;
  border-radius: 25px;
  top: 95%;
  left: 58%;
  font-size: 10px;
}

.izKjve .custom-arrows-div{
  top:10% !important;
}

@media screen and (max-width: 767.9px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 25px;
  }

  .desktop-bg,
  .dots-group {
    display: none;
  }

  .mobile-bg {
    display: block;
  }
}

@media screen and (min-width: 320px) and (max-width: 376px) {
  .wsj-business{
    margin-top: -5rem;
    position: absolute;
    width: 100%
  }
  .wsj-btn {
    top: 31.2% !important;
    left: 8% !important;
    font-size: 7px !important;
  }
}

@media screen and (min-width: 376px) and (max-width: 400px) {
  .mobile-bg {
    width: 400px;
  }
  .wsj-business{
    margin-top: -5rem;
    position: absolute;
    width: 100%
  }
}

@media screen and (min-width: 401px) and (max-width: 500px) {
  .mobile-bg {
    width: 500px;
  }
  .wsj-business{
    margin-top: -8rem;
    position: absolute;
    width: 100%
  }
}

@media screen and (min-width: 501px) and (max-width: 600px) {
  .mobile-bg {
    width: 600px;
  }
  .wsj-business{
    margin-top: -12rem;
    position: absolute;
    width: 100%
  }
}

@media screen and (min-width: 601px) and (max-width: 700px) {
  .mobile-bg {
    width: 700px;
  }
  .dots-group {
    max-width: 50px;
  }
  .wsj-business{
    margin-top: -15rem;
    position: absolute;
    width: 100%
  }
}

@media screen and (min-width: 701px) and (max-width: 767px) {
  .mobile-bg {
    width: 100%;
  }
  .wsj-business{
    margin-top: -18rem;
    position: absolute;
    width: 100%
  }
}

@media screen and (min-width: 768px) and (max-width: 794px) {
  
  .wsj-business{
    margin-top: -15rem;
    margin-right: 20rem;
    position: absolute;
    width: 50% 
    
  }
  .desktop-bg{
    height: 20rem !important;
  }
}


@media screen and (min-width: 794px) and (max-width: 991.9px) {
  
  .wsj-business{
    margin-top: -17rem;
    margin-right: 20rem;
    position: absolute;
    width: 50%;
  }
  .desktop-bg{
    height: 20rem !important;
    width: 100% !important;
  }
}
@media screen and (min-width: 940px) and (max-width: 991.9px) {
  
  .wsj-business{
    margin-top: -17.8rem;
    margin-right: 20rem;
    position: absolute;
    width: 50%;
  }

}

@media screen and (min-width: 992px) and (max-width: 1899.9px) {
  .container-pad {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .desktop-bg {
    height: 500px;
  }
}

@media screen and (min-width: 1416px) and (max-width: 1919.9px) {
  .desktop-bg {
    width: 100% !important;
    height: 630px !important;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2000px) {
  .desktop-bg {
    width: 2000px;
    height: 630px !important;
  }
  .dots-group {
    max-width: 80px;
  }
}

@media screen and (min-width: 2001px) and (max-width: 3000px) {
  .desktop-bg {
    width: 3000px;
    height: 620px !important;
  }
  .dots-group {
    max-width: 100px;
  }
}

@media screen and (min-width: 3001px) and (max-width: 4000px) {
  .desktop-bg {
    width: 4000px;
    height: 600px !important;
  }
  .dots-group {
    max-width: 100px;
  }
}


@media screen and (min-width: 992px) and (max-width: 1000px){
  .wsj-business{
    margin-top: -11rem;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1240px){
  .wsj-business{
    margin-top: -10rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px){
  .wsj-business{
    margin-top: -12rem;
  }
}

@media screen and (min-width: 1500px) {
  .wsj-business{
    margin-top: -18rem;
  }
}
.navbar1{
  background:transparent !important;
    z-index: 999;
    box-shadow:none;
    padding-top: 1em;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    justify-content: center !important;
}
.navbar1.active{ 
  background: radial-gradient(78.52% 125.63% at 41.41% -16.63%, #FBF8F3 0%, #FBF8F3 29.17%, #FBF8F3 57.29%, #FBF8F3 88%, #FBF8F3 100%) !important;
}


@media(max-width:991px){
    .navbar1{
      transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        background: transparent !important;
    z-index: 15;

    }
    .navbar1.active,
    .navbar1-collapse{
      background: radial-gradient(78.52% 125.63% at 41.41% -16.63%, #FBF8F3 0%, #FBF8F3 29.17%, #FBF8F3 57.29%, #FBF8F3 88%, #FBF8F3 100%) !important;
      z-index: 10;
    }


  }
@media(max-width:320px){

    .navbar1{
        padding-right: 1em!important;
    }
}

@media screen and (min-width:1400px) {
  .navbar1{
      padding-right: 3em;
      padding-top: 1.5em;
  }
.navbar1.active{
  padding-top: 2em;  
  }
}

.navbar-brand {
  text-decoration: none;
  white-space: normal !important;
}

@media screen and (min-width: 320px) and (max-width: 740px) {
  .modal-content{
    margin-top: 3.5rem;
  }
}